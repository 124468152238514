import React, { useState, useEffect } from 'react';
import { Search } from './Search';
import { AddWorkSpaceButton } from './AddWorkSpaceButton';
import { getWorkspaces, addWorkspace as firestoreAddWorkspace, deleteWorkspace as firestoreDeleteWorkspace, addWorkplaceItem as firestoreAddWorkplaceItem, deleteWorkplaceItem as firestoreDeleteWorkplaceItem, fetchSettingsFromFirestore, getUserPlan } from '../config/firefunctions';
import { Workspace } from './Workspace';
import { AllApps } from './AllApps'; 
import { Projects } from './Projects'; // Import the Projects component
import { UpgradePlanModal } from './UpgradePlanModal'; 
import Loading from './Loading';
import { DismissibleNotification } from './DismissibleNotification'; 
import '../css/AppHome.css';
import { useAuth } from '../config/AuthContext'; 
import { planLimits } from '../config/PricingOptions';
import ZenTimer from './ZenTimer';

export function AppHome() {
  const { user } = useAuth();
  const [workspaces, setWorkspaces] = useState([]);
  const [settings, setSettings] = useState({
    showTitle: true,
    showURL: false,
    showDescription: false,
    showSearchBar: false,
    itemBackgroundColor: '#ffffff',
    pageBackgroundColor: '#ffffff'
  });
  const [userPlan, setUserPlan] = useState('Free');
  const [isLoading, setIsLoading] = useState(true); // State to manage loading
  const [view, setView] = useState('workspaces'); // State to manage the current view
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  const fetchWorkspaces = async () => {
    if (user) {
      setIsLoading(true); // Start loading
      const userId = user.uid;
      const fetchedWorkspaces = await getWorkspaces(userId);
      const fetchedSettings = await fetchSettingsFromFirestore(userId);
      const fetchedUserPlan = await getUserPlan(userId);
      setTimeout(() => { // Ensure loading lasts at least 2 seconds
        setWorkspaces(fetchedWorkspaces);
        setSettings(fetchedSettings || settings);
        setUserPlan(fetchedUserPlan);
        setIsLoading(false); // End loading
      }, 1000);
    }
  };

  useEffect(() => {
    fetchWorkspaces();
  }, [user]);

  const deleteWorkspace = async (id) => {
    await firestoreDeleteWorkspace(user.uid, id);
    await fetchWorkspaces(); // Refetch after delete
  };

  const handleAddWorkspace = async (workspace) => {
    if (workspaces.length < planLimits[userPlan].maxWorkspaces) {
      const newWorkspace = {
        userId: user.uid,
        ...workspace,
        items: [],
      };
      await firestoreAddWorkspace(newWorkspace);
      await fetchWorkspaces(); // Refetch after add
    } else {
      setIsUpgradeModalOpen(true);
    }
  };

  const addWorkplaceItem = async (workspaceId, item) => {
    const workspace = workspaces.find(w => w.id === workspaceId);
    if (workspace.items.length < planLimits[userPlan].maxItemsPerWorkspace) {
      await firestoreAddWorkplaceItem(user.uid, workspaceId, item);
      await fetchWorkspaces(); // Refetch after adding item
    } else {
      setIsUpgradeModalOpen(true);
    }
  };

  const deleteWorkplaceItem = async (workspaceId, itemId) => {
    await firestoreDeleteWorkplaceItem(user.uid, workspaceId, itemId);
    await fetchWorkspaces(); // Refetch after deleting item
  };

  const addProject = (project) => {
    console.log("New Project Added:", project);
    // Add your logic to handle the newly added project
  };

  return (
    <div className='apphome'>
      <DismissibleNotification />
      {settings.showSearchBar && <Search />}
      <div className="toggle-buttons">
        <button 
          className={view === 'workspaces' ? 'active' : ''} 
          onClick={() => setView('workspaces')}
        >
          Workspaces
        </button>
        <button 
          className={view === 'apps' ? 'active' : ''} 
          onClick={() => setView('apps')}
        >
          All Apps
        </button>
        <button 
          className={view === 'projects' ? 'active' : ''} 
          onClick={() => setView('projects')}
        >
          Projects
        </button>
        <ZenTimer />
      </div>


      {view === 'workspaces' && <AddWorkSpaceButton onAdd={handleAddWorkspace} />}
      {isLoading ? (
        <Loading /> 
      ) : (
        view === 'workspaces' ? (
          workspaces.length === 0 ? (
            <div className="no-workspaces">
              <h2>Welcome to ZenWorkspace.io!</h2>
              <p>It looks like you don't have any workspaces yet. Follow these steps to get started:</p>
              <ol>
                <li>Click on the "Add Workspace" button above to create a new workspace.</li>
                <li>Once you've created a workspace, you can add items (websites or apps) to it.</li>
                <li>Use the "Edit" button to customize your workspace and manage your items.</li>
                <li>You can easily open all items in a workspace by clicking the "Open All" button.</li>
              </ol>
              <p>You can also use the search bar to search with Google</p>
            </div>
          ) : (
            workspaces.map(workspace => (
              <Workspace
                key={workspace.id}
                userId={user.uid} 
                workspace={workspace}
                settings={settings} 
                onDelete={() => deleteWorkspace(workspace.id)}
                onAddItem={(item) => addWorkplaceItem(workspace.id, item)}
                onDeleteItem={(itemId) => deleteWorkplaceItem(workspace.id, itemId)}
                refetchWorkspaces={fetchWorkspaces}
              />
            ))
          )
        ) : view === 'apps' ? (
          <AllApps 
            workspaces={workspaces} 
            onDeleteItem={deleteWorkplaceItem} 
            userId={user.uid} 
            settings={settings} 
          />
        ) : (
          <Projects 
            userId={user.uid} 
            workspaces={workspaces} 
            addProject={addProject}
            userPlan={userPlan} // Pass user plan to Projects
          />
        )
      )}
      <UpgradePlanModal 
        isOpen={isUpgradeModalOpen} 
        onClose={() => setIsUpgradeModalOpen(false)} 
        currentPlan={userPlan} 
      />
    </div>
  );
}
