// src/config/CommonApps.js
export const apps = [
  { name: 'Slack', protocol: 'slack://open', web: 'https://slack.com' },
  { name: 'Zoom', protocol: 'zoommtg://zoom.us/join', web: 'https://zoom.us' },
  { name: 'Spotify', protocol: 'spotify://', web: 'https://spotify.com' },
  { name: 'Microsoft Teams', protocol: 'msteams://', web: 'https://teams.microsoft.com' },
  { name: 'Discord', protocol: 'discord://', web: 'https://discord.com' },
  { name: 'Figma', protocol: 'figma://', web: 'https://figma.com' },
  { name: 'Notion', protocol: 'notion://', web: 'https://notion.so' },
  { name: 'Dropbox', protocol: 'dropbox://', web: 'https://dropbox.com' },
  { name: 'Google Drive', protocol: 'googledrive://', web: 'https://drive.google.com' },
  { name: 'OneDrive', protocol: 'onedrive://', web: 'https://onedrive.live.com' },
  { name: 'Skype', protocol: 'skype://', web: 'https://skype.com' },
  { name: 'Evernote', protocol: 'evernote://', web: 'https://evernote.com' },
  { name: 'Trello', protocol: 'trello://', web: 'https://trello.com' },
  { name: 'Asana', protocol: 'asana://', web: 'https://asana.com' },
  { name: 'WhatsApp', protocol: 'whatsapp://', web: 'https://web.whatsapp.com/' },
  { name: 'Visual Studio Code', protocol: 'vscode://', web: 'https://code.visualstudio.com' },
  { name: 'Postman', protocol: 'postman://', web: 'https://postman.com' },
  { name: 'Adobe Creative Cloud', protocol: 'creativecloud://', web: 'https://creativecloud.adobe.com' },
  { name: 'Jira', protocol: 'jira://', web: 'https://jira.com' },
  { name: 'GitHub Desktop', protocol: 'github-desktop://', web: 'https://desktop.github.com' },
  { name: 'Obsidian', protocol: 'obsidian://', web: 'https://obsidian.md' },
  { name: 'Atom', protocol: 'atom://', web: 'https://atom.io' },
  { name: 'Todoist', protocol: 'todoist://', web: 'https://todoist.com' },
  { name: 'Airtable', protocol: 'airtable://', web: 'https://airtable.com' },
  { name: 'Mattermost', protocol: 'mattermost://', web: 'https://mattermost.com' },
  { name: 'Basecamp', protocol: 'basecamp://', web: 'https://basecamp.com' },
  { name: 'ClickUp', protocol: 'clickup://', web: 'https://clickup.com' },
  { name: 'Miro', protocol: 'miro://', web: 'https://miro.com' },
  { name: 'Outlook', protocol: 'outlook://', web: 'https://outlook.com' },
  { name: 'Steam', protocol: 'steam://', web: 'https://store.steampowered.com' } 
];
