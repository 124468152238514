export const initialDemoWorkspaces = [
    {
      id: 'demo1',
      title: 'Project Management',
      items: [
        {
          id: 'item_1',
          title: 'Trello',
          url: 'https://trello.com',
          icon: 'https://www.google.com/s2/favicons?sz=256&domain=trello.com',
          homepageUrl: 'https://trello.com',
          commonApp: false,
          isCustomUrl: false,
          openChoice: 'website'
        },
        {
          id: 'item_2',
          title: 'Jira',
          url: 'https://jira.com',
          icon: 'https://www.google.com/s2/favicons?sz=256&domain=jira.com',
          homepageUrl: 'https://jira.com',
          commonApp: false,
          isCustomUrl: false,
          openChoice: 'website'
        },
        {
          id: 'item_3',
          title: 'Asana',
          url: 'https://asana.com',
          icon: 'https://www.google.com/s2/favicons?sz=256&domain=asana.com',
          homepageUrl: 'https://asana.com',
          commonApp: false,
          isCustomUrl: false,
          openChoice: 'website'
        },
        {
          id: 'item_4',
          title: 'Notion',
          url: 'https://notion.so',
          icon: 'https://www.google.com/s2/favicons?sz=256&domain=notion.so',
          homepageUrl: 'https://notion.so',
          commonApp: false,
          isCustomUrl: false,
          openChoice: 'website'
        },
      ],
    },
    {
      id: 'demo2',
      title: 'Gaming',
      items: [
        {
          id: 'item_5',
          title: 'Steam',
          url: 'steam://open',
          icon: 'https://www.google.com/s2/favicons?sz=256&domain=store.steampowered.com',
          homepageUrl: 'https://store.steampowered.com',
          commonApp: true,
          isCustomUrl: false,
          openChoice: 'app'
        },
        {
          id: 'item_6',
          title: 'Discord',
          url: 'discord://',
          icon: 'https://www.google.com/s2/favicons?sz=256&domain=discord.com',
          homepageUrl: 'https://discord.com',
          commonApp: true,
          isCustomUrl: false,
          openChoice: 'app'
        },
      ],
    },
    {
      id: 'demo3',
      title: 'Development',
      items: [
        {
          id: 'item_7',
          title: 'GitHub',
          url: 'https://github.com',
          icon: 'https://www.google.com/s2/favicons?sz=256&domain=github.com',
          homepageUrl: 'https://github.com',
          commonApp: false,
          isCustomUrl: false,
          openChoice: 'website'
        },
        {
          id: 'item_8',
          title: 'GitLab',
          url: 'https://gitlab.com',
          icon: 'https://www.google.com/s2/favicons?sz=256&domain=gitlab.com',
          homepageUrl: 'https://gitlab.com',
          commonApp: false,
          isCustomUrl: false,
          openChoice: 'website'
        },
        {
          id: 'item_9',
          title: 'Visual Studio Code',
          url: 'vscode://',
          icon: 'https://www.google.com/s2/favicons?sz=256&domain=code.visualstudio.com',
          homepageUrl: 'https://code.visualstudio.com',
          commonApp: true,
          isCustomUrl: false,
          openChoice: 'app'
        },
      ],
    },
  ];