// // src/config/fetchmeta.js
// const CORS_PROXY = 'https://corsproxy.io/?';

// const getGoogleFaviconUrl = (domain) => {
//   return `https://www.google.com/s2/favicons?sz=256&domain=${domain}`;
// };

// const limitWords = (text, limit) => {
//   const words = text.split(' ');
//   if (words.length > limit) {
//     return words.slice(0, limit).join(' ') + '...';
//   }
//   return text;
// };

// export const fetchIcon = (url) => {
//   const domain = new URL(url).hostname;
//   return getGoogleFaviconUrl(domain);
// };

// export const fetchTitleAndDescription = async (url) => {
//   try {
//     const response = await fetch(`${CORS_PROXY}${url}`);
//     const html = await response.text();

//     const parser = new DOMParser();
//     const doc = parser.parseFromString(html, 'text/html');

//     const title = doc.querySelector('title')?.innerText || 'No title';
//     let description = doc.querySelector('meta[name="description"]')?.getAttribute('content') || 'No description available';
//     if (description) {
//       description = limitWords(description, 8);
//     }

//     return { title, description };
//   } catch (error) {
//     console.error('Error fetching metadata:', error);
//     return { title: 'No title', description: 'No description available' };
//   }
// };

// export const fetchmeta = async (url) => {
//   const icon = fetchIcon(url);
//   const { title, description } = await fetchTitleAndDescription(url);
//   return { title, description, icon };
// };

import zenlogoCircle from '../imgs/zenlogoCircle.png';

const CORS_PROXY = 'https://corsproxy.io/?';

const getGoogleFaviconUrl = (domain) => {
  return `https://www.google.com/s2/favicons?sz=256&domain=${domain}`;
};

export const fetchIcon = (url) => {
  try {
    const domain = new URL(url).hostname;
    return getGoogleFaviconUrl(domain);
  } catch (error) {
    console.error('Invalid URL for fetching icon:', error);
    return '';
  }
};

export const fetchIconForApp = (webUrl) => {
  try {
    const domain = new URL(webUrl).hostname;
    return getGoogleFaviconUrl(domain);
  } catch (error) {
    console.error('Invalid web URL for fetching app icon:', error);
    return '';
  }
};

export const fetchTitleAndDescription = async (url) => {
  try {
    const fullUrl = !url.startsWith('http://') && !url.startsWith('https://') ? `https://${url}` : url;
    const response = await fetch(`${CORS_PROXY}${fullUrl}`);
    const html = await response.text();

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const title = doc.querySelector('title')?.innerText || 'No title';
    let description = doc.querySelector('meta[name="description"]')?.getAttribute('content') || 'No description available';

    return { title, description };
  } catch (error) {
    console.error('Error fetching metadata:', error);
    return { title: 'No title', description: 'No description available' };
  }
};

export const fetchmeta = async (url) => {
  const icon = fetchIcon(url) || zenlogoCircle;
  const { title, description } = await fetchTitleAndDescription(url);
  return { title, description, icon };
};



