import React, { useState, useEffect } from 'react';
import '../css/ZenTimer.css';

const ZenTimer = () => {
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [initialTotalSeconds, setInitialTotalSeconds] = useState(0);
    const [timerType, setTimerType] = useState('work'); // 'work' or 'zen'

    useEffect(() => {
        let timer;
        const totalSeconds = hours * 3600 + minutes * 60 + seconds;

        if (isRunning && totalSeconds > 0) {
            timer = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(prevSeconds => prevSeconds - 1);
                } else if (minutes > 0) {
                    setMinutes(prevMinutes => prevMinutes - 1);
                    setSeconds(59);
                } else if (hours > 0) {
                    setHours(prevHours => prevHours - 1);
                    setMinutes(59);
                    setSeconds(59);
                }
            }, 1000);
        } else if (totalSeconds === 0 && isRunning) {
            setIsRunning(false);
            setShowOverlay(true);
        }

        return () => clearInterval(timer);
    }, [isRunning, hours, minutes, seconds]);

    const handleStart = () => {
        const totalSeconds = hours * 3600 + minutes * 60 + seconds;
        setInitialTotalSeconds(totalSeconds);
        if (totalSeconds > 0) {
            setIsRunning(true);
            setShowSettings(false);
            if (timerType === 'zen') {
                setShowOverlay(true);
            }
        }
    };

    const handleReset = () => {
        setHours(0);
        setMinutes(0);
        setSeconds(0);
        setIsRunning(false);
        setShowOverlay(false);
        setInitialTotalSeconds(0);
        setShowSettings(false);
    };

    const handleCloseSettings = () => {
        setShowSettings(false);
    };

    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    const percentage = initialTotalSeconds > 0 ? (1 - totalSeconds / initialTotalSeconds) * 100 : 0;
    const backgroundStyle = {
        background: `linear-gradient(to right, var(--color-primary), white ${percentage}%)`,
        color: isRunning ? '#333' : 'inherit'
    };

    return (
        <div className="zentimer-wrapper">
            <button 
                className="zentimer-button" 
                onClick={() => setShowSettings(!showSettings)} 
                style={isRunning ? backgroundStyle : {}}
            >
                {isRunning ? (
                    <span>{`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</span>
                ) : (
                    <span>Zen Timer 🧘</span>
                )}
            </button>
            {showSettings && !isRunning && (
                <div className="timer-settings">
                    <button className="close-settings" onClick={handleCloseSettings}>X</button>
                    <span className='directions'>Set a timer for your preferred work or break and keep your Zen going</span>
                    <div className="timer-type-toggle">
                            <label>
                                <input
                                    type="radio"
                                    name="timerType"
                                    value="work"
                                    checked={timerType === 'work'}
                                    onChange={() => setTimerType('work')}
                                />
                                Work Timer
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="timerType"
                                    value="zen"
                                    checked={timerType === 'zen'}
                                    onChange={() => setTimerType('zen')}
                                />
                                Zen Timer 🧘
                            </label>
                        </div>
                    <div className="timer-input">
                        <div>
                            <input
                                type="number"
                                value={hours}
                                onChange={(e) => setHours(Number(e.target.value))}
                                placeholder="HH"
                                disabled={isRunning}
                                min="0"
                            />
                            <span>Hours</span>
                        </div>
                        <div>
                            <input
                                type="number"
                                value={minutes}
                                onChange={(e) => setMinutes(Number(e.target.value))}
                                placeholder="MM"
                                disabled={isRunning}
                                min="0"
                                max="59"
                            />
                            <span>Minutes</span>
                        </div>
                       
                        <button onClick={handleStart} disabled={isRunning || (hours === 0 && minutes === 0 && seconds === 0)}>Start</button>
                    </div>
                </div>
            )}
            {showOverlay && (
                <div className="overlay">
                    <div className="overlay-content">
                        <h2>{timerType === 'zen' ? 'Zen Time 🧘' : 'Timer has ended!'}</h2>
                        {timerType === 'zen' ? (
                            <div className="overlay-actions">
                                <span>{`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</span>
                                <button onClick={handleReset}>Stop Zening</button>
                            </div>
                        ) : (
                            <button onClick={handleReset}>Close</button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ZenTimer;
