import '../css/Home.css';
import { useEffect } from 'react';
import { ScreensGif } from './ScreensGif';
import Icon from './Icon';
import { PiBrowsersLight } from 'react-icons/pi';
import { PiMouseLeftClickFill } from "react-icons/pi";
import { VscRobot } from "react-icons/vsc";
import { IoIosCloud } from "react-icons/io";

export function Home() {
  useEffect(() => {
    const storyFeaturesContainer = document.querySelector('.story-features-container');
    const bottomSection = document.querySelector('.bottom-section');

    window.addEventListener('scroll', () => {
      const scrollPosition = window.scrollY + window.innerHeight;

      if (scrollPosition > storyFeaturesContainer.offsetTop + 100) {
        storyFeaturesContainer.style.opacity = '1';
        storyFeaturesContainer.style.transform = 'translateY(0)';
      }

      if (scrollPosition > bottomSection.offsetTop + 100) {
        bottomSection.style.opacity = '1';
        bottomSection.style.transform = 'translateY(0)';
      }
    });

    const features = document.querySelectorAll('.feature-item');
    features.forEach((feature, index) => {
      setTimeout(() => {
        feature.style.opacity = '1';
        feature.style.transform = 'translateY(0)';
      }, 3000 + index * 200);
    });
  }, []);

  return (
    <div className="home-container">
      <header className="hero-section">
        <h1>
          Simplify Your Digital Life,
          <br />
          Optimize for Productivity
        </h1>
        <p>Organize your digital life with custom <span style={{ fontWeight: 'bold' }}>workspaces</span></p>
        <button className="cta-button" onClick={() => window.location.href = '/login'}>Get Started now</button>
        <a href="/LiveDemo" className="demo-cta">Or check out this <b>live demo</b></a>
      </header>
      
      <ScreensGif className="screens-gif" />

      <div className="story-features-container">
        <section className="story-section">
          <h2>Our Story</h2>
          <p>
            In today's fast-paced world, managing multiple online tasks can be overwhelming. <br />
            <span style={{ fontWeight: 'bold' }}>ZenWorkspace.io</span> was born out of the need for a streamlined solution to organize and access your most-used websites effortlessly.
            Our mission is to help you focus on what matters by <span style={{ fontWeight: 'bold' }}>creating custom workspaces</span> for different aspects of your digital life.
          </p>
        </section>
        
        <section className="features-section">
          <h2>Features</h2>
          <div className="features-grid">
            <div className="feature-item">
              <Icon icon={PiBrowsersLight} size="4em" color="var(--color-feature-bg)" className={"feature-icon"} />
              <h3>Custom Workspaces</h3>
              <p>Create workspaces tailored to your needs, whether it's for work, leisure, or any other purpose.</p>
            </div>
            <div className="feature-item">
            <Icon icon={PiMouseLeftClickFill} size="4em" color="var(--color-feature-bg)" className={"feature-icon"} />
              <h3>Quick Access</h3>
              <p>Open all your essential websites with a single click, saving you time and effort.</p>
            </div>
            <div className="feature-item">
            <Icon icon={VscRobot} size="4em" color="var(--color-feature-bg)" className={"feature-icon"} />
              <h3>User-Friendly Interface</h3>
              <p>Enjoy a clean, intuitive design that makes organizing your online tasks a breeze.</p>
            </div>
            <div className="feature-item">
            <Icon icon={IoIosCloud} size="4em" color="var(--color-feature-bg)" className={"feature-icon"} />
              <h3>Cloud Sync</h3>
              <p>Access your workspaces from any device with seamless cloud synchronization.</p>
            </div>
          </div>
        </section>
      </div>
      
      <section className="bottom-section">
        <h1>Increase your productivity effortlessly with ZenWorkspace</h1>
        <button className="cta-button" onClick={() => window.location.href = '/login'}>Get Started</button>
      </section>
    </div>
  );
}
