import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { getProjectTodos, addTodoToFirestore, deleteTodoFromFirestore } from '../config/firefunctions';
import { FaEdit } from 'react-icons/fa';
import '../css/Todolist.css'; // Assuming you have a CSS file for TodoList

export const TodoList = ({ userId, projectId }) => {
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState('');
  const [showDeleteIcons, setShowDeleteIcons] = useState(false);

  useEffect(() => {
    const fetchTodos = async () => {
      const projectTodos = await getProjectTodos(userId, projectId);
      setTodos(projectTodos);
    };
    fetchTodos();
  }, [userId, projectId]);

  const handleAddTodo = async () => {
    if (newTodo) {
      await addTodoToFirestore(userId, projectId, newTodo);
      setTodos([...todos, { id: `todo-${Date.now()}`, text: newTodo }]);
      setNewTodo('');
    }
  };

  const handleDeleteTodo = async (todoId) => {
    const newTodos = todos.filter(todo => todo.id !== todoId);
    setTodos(newTodos);
    await deleteTodoFromFirestore(userId, projectId, todoId);
  };

  const handleChange = (value) => {
    setNewTodo(value);
  };

  return (
    <div className="todo-list">
      <div className='top-todo'>
        <h3>To-Do List</h3>
            <div className='todo-buttons'>
                <button onClick={handleAddTodo}>Add To-Do</button>
                <button onClick={() => setShowDeleteIcons(!showDeleteIcons)} className="edit-mode-btn">
                    <FaEdit />
                </button>
            </div>
      </div>
      <div className='add-todo-form'>
        <ReactQuill
          value={newTodo}
          onChange={handleChange}
          modules={TodoList.modules}
          formats={TodoList.formats}
          theme="snow"
          placeholder="Add a new to-do"
        />
      </div>
      <ul>
        {todos.map((todo, index) => (
          <li key={index} className="todo-item">
            <div dangerouslySetInnerHTML={{ __html: todo.text }}></div>
            {showDeleteIcons && (
              <button
                className="delete-todo-btn"
                onClick={() => handleDeleteTodo(todo.id)}
              >
                &times;
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

// Quill modules and formats
TodoList.modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ 'list': 'bullet' }]
  ],
};

TodoList.formats = [
  'bold', 'italic', 'underline',
  'list', 'bullet'
];
