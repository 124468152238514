import React from 'react';
import '../css/UpgradePlanModal.css'; // Make sure to create this CSS file for styling

export const UpgradePlanModal = ({ isOpen, onClose, currentPlan }) => {
  if (!isOpen) return null;

  const upgradeMessage = currentPlan === 'Free' ? 
    'Upgrade to Pro for more workspaces and unlimited items per workspace and unlimited projects. ' :
    'Upgrade to SuperUser for unlimited workspaces and items.';

  return (
    <div className="modal-overlay">
      <div className="UpgradePlan-modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Upgrade Your Plan</h2>
        <p>{upgradeMessage}</p>
        <button onClick={() => window.location.href = '/pricing'}>Upgrade Now</button>
      </div>
    </div>
  );
};
