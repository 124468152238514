// src/comps/AddProjectModal.js
import React, { useState, useEffect } from 'react';
import '../css/AddProjectModal.css'; // Assuming you have a CSS file for modals

export const AddProjectModal = ({ isOpen, onClose, onSubmit, workspaces }) => {
  const [projectName, setProjectName] = useState('');
  const [selectedWorkspace, setSelectedWorkspace] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setProjectName('');
      setSelectedWorkspace('');
      setError('');
    }
  }, [isOpen]);

  const handleProjectNameChange = (e) => setProjectName(e.target.value);
  const handleWorkspaceChange = (e) => setSelectedWorkspace(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!projectName) {
      setError('Project name is required');
      return;
    }

    const project = {
      name: projectName,
      workspace: selectedWorkspace ? workspaces.find(ws => ws.id === selectedWorkspace) : null,
    };
    onSubmit(project);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Add New Project</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <label>
            Project Name:
            <input type="text" value={projectName} onChange={handleProjectNameChange} required />
          </label>
          <label>
            Choose a Workspace:
            <select value={selectedWorkspace} onChange={handleWorkspaceChange} required>
              <option value="">--Select Workspace--</option>
              {workspaces.map(ws => (
                <option key={ws.id} value={ws.id}>{ws.title}</option>
              ))}
            </select>
          </label>
          <div className="modal-actions">
            <button type="submit">Submit</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};
