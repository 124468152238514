import React, { useState, useEffect } from 'react';
import { Workspace } from './Workspace';
import { AllApps } from './AllApps';
import { Projects } from './Projects';
import { AddWorkSpaceButton } from './AddWorkSpaceButton';
import { UpgradePlanModal } from './UpgradePlanModal';
import { initialDemoWorkspaces } from '../config/Demodata';
import Loading from './Loading';
import ZenTimer from './ZenTimer'; // Import ZenTimer
import '../css/LiveDemo.css';

export const LiveDemo = () => {
  const [demoWorkspaces, setDemoWorkspaces] = useState([]);
  const [view, setView] = useState('workspaces'); // Add this line to initialize view state
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false); // Add this line to initialize modal state

  useEffect(() => {
    const storedWorkspaces = sessionStorage.getItem('demoWorkspaces');
    if (storedWorkspaces) {
      setDemoWorkspaces(JSON.parse(storedWorkspaces));
    } else {
      sessionStorage.setItem('demoWorkspaces', JSON.stringify(initialDemoWorkspaces));
      setDemoWorkspaces(initialDemoWorkspaces);
    }
  }, []);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('demoWorkspaces');
    };
  }, []);

  const handleDelete = (workspaceId) => {
    const updatedWorkspaces = demoWorkspaces.filter(workspace => workspace.id !== workspaceId);
    setDemoWorkspaces(updatedWorkspaces);
    sessionStorage.setItem('demoWorkspaces', JSON.stringify(updatedWorkspaces));
  };

  const handleAddItem = (workspaceId, newItem) => {
    const updatedWorkspaces = demoWorkspaces.map(workspace => {
      if (workspace.id === workspaceId) {
        return { ...workspace, items: [...workspace.items, newItem] };
      }
      return workspace;
    });
    setDemoWorkspaces(updatedWorkspaces);
    sessionStorage.setItem('demoWorkspaces', JSON.stringify(updatedWorkspaces));
  };

  const handleDeleteItem = (workspaceId, itemId) => {
    const updatedWorkspaces = demoWorkspaces.map(workspace => {
      if (workspace.id === workspaceId) {
        return { ...workspace, items: workspace.items.filter(item => item.id !== itemId) };
      }
      return workspace;
    });
    setDemoWorkspaces(updatedWorkspaces);
    sessionStorage.setItem('demoWorkspaces', JSON.stringify(updatedWorkspaces));
  };

  const handleAddWorkspace = (workspace) => {
    const newWorkspace = {
      id: `demo_${Date.now()}`,
      title: workspace.title,
      items: [],
    };
    const updatedWorkspaces = [...demoWorkspaces, newWorkspace];
    setDemoWorkspaces(updatedWorkspaces);
    sessionStorage.setItem('demoWorkspaces', JSON.stringify(updatedWorkspaces));
  };

  const addProject = (project) => {
    console.log("New Project Added:", project);
  };

  return (
    <div className="apphome live-demo-container">
      <div className="toggle-buttons">
        <button 
          className={view === 'workspaces' ? 'active' : ''} 
          onClick={() => setView('workspaces')}
        >
          Workspaces
        </button>
        <button 
          className={view === 'apps' ? 'active' : ''} 
          onClick={() => setView('apps')}
        >
          All Apps
        </button>
        <button 
          className={view === 'projects' ? 'active' : ''} 
          onClick={() => setView('projects')}
        >
          Projects
        </button>
        <ZenTimer /> {/* Add ZenTimer here */}
      </div>
      {view === 'workspaces' && <AddWorkSpaceButton onAdd={handleAddWorkspace} />}
      {view === 'workspaces' && demoWorkspaces.length === 0 ? (
        <div className="no-workspaces">
          <h2>Welcome to ZenWorkspace.io!</h2>
          <p>It looks like you don't have any workspaces yet. Follow these steps to get started:</p>
          <ol>
            <li>Click on the "Add Workspace" button above to create a new workspace.</li>
            <li>Once you've created a workspace, you can add items (websites or apps) to it.</li>
            <li>Use the "Edit" button to customize your workspace and manage your items.</li>
            <li>You can easily open all items in a workspace by clicking the "Open All" button.</li>
          </ol>
          <p>You can also use the search bar to search with Google</p>
        </div>
      ) : view === 'workspaces' ? (
        demoWorkspaces.map(workspace => (
          <Workspace
            key={workspace.id}
            userId={'demo_user'}
            workspace={workspace}
            settings={{}}
            onDelete={() => handleDelete(workspace.id)}
            onAddItem={(newItem) => handleAddItem(workspace.id, newItem)}
            onDeleteItem={(itemId) => handleDeleteItem(workspace.id, itemId)}
            refetchWorkspaces={() => {}}
            isDemo={true}
          />
        ))
      ) : view === 'apps' ? (
        <AllApps 
          workspaces={demoWorkspaces} 
          onDeleteItem={handleDeleteItem} 
          userId={'demo_user'}
          settings={{}} 
        />
      ) : (
        <Projects 
          userId={'demo_user'} 
          workspaces={demoWorkspaces} 
          addProject={addProject}
          isDemo={true}
        />
      )}
      <UpgradePlanModal 
        isOpen={isUpgradeModalOpen} 
        onClose={() => setIsUpgradeModalOpen(false)} 
        currentPlan="Free" 
      />
    </div>
  );
};
