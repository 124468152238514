import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Nav.css';
import { SideMenu } from './Sidemenu';
import { useAuth } from '../config/AuthContext';
import zenlogo from '../imgs/zenlogo.png';

export const Nav = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const { user } = useAuth();

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  return (
    <div className='full-width'>
      <nav className="sticky-nav">
        <a href="/" className="logo-link">
          <img src={zenlogo} alt="ZenWorkspace.io Logo" className="logo-img" height={'40px'} width={'40px'} />
          <span className="logo-text">ZenWorkspace.io</span>
        </a>
        <ul>
          {user ? (
            <li>
              <img
                src={user.photoURL}
                alt="User profile"
                className="user-avatar"
                onClick={toggleSideMenu}
              />
            </li>
          ) : (
            <>
              <li>
                <button className="menu-button" onClick={toggleSideMenu}>
                  Sign in
                </button>
              </li>
              <li className='pricing-btn'>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li className='pricing-btn'>
                <Link to="/Livedemo">Live Demo</Link>
              </li>
            </>
          )}
        </ul>
      </nav>
      <div className='bottom-line'></div>
      {isSideMenuOpen && <SideMenu isOpen={isSideMenuOpen} toggleMenu={toggleSideMenu} />}
    </div>
  );
};
