// src/pages/LoginPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../config/AuthContext';
import { Login } from '../comps/Login';
import '../css/LoginPage.css';

export const LoginPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/MyWorkSpaces'); // Redirect to '/MyWorkSpaces' if user is logged in
    }
  }, [user, navigate]);

  return (
    <div className="login-page">
      <Login />
    </div>
  );
};
