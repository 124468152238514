// src/comps/Login.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider } from '../config/firebase';
import { signInWithPopup } from 'firebase/auth';
import '../css/Login.css';
import { FcGoogle } from "react-icons/fc";
import { useAuth } from '../config/AuthContext';

export const Login = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      setUser(result.user);
      navigate('/MyWorkSpaces'); // Redirect to '/workspace' after login
    } catch (error) {
      console.error("Error during login with Google:", error);
    }
  };

  return (
    <div className="login-container">
      <h2>Hi, There!</h2>
      <button className="google-login-btn" onClick={handleGoogleLogin}>
        <FcGoogle style={{ marginRight: '10px' }} /> Sign in with Google
      </button>
      <span>To continue, Please Sign in</span>
    </div>
  );
};
