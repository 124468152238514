import React, { useEffect, useState } from 'react';
import '../css/WorkspaceSettings.css';
import { fetchSettingsFromFirestore, saveSettingsToFirestore } from '../config/firefunctions';

export const WorkspaceSettings = ({ userId, settings: initialSettings, onSettingsChange }) => {
  const [settings, setSettings] = useState(initialSettings);

  useEffect(() => {
    const loadSettings = async () => {
      const localSettings = JSON.parse(localStorage.getItem('workspaceSettings'));
      if (localSettings) {
        setSettings(localSettings);
      } else {
        const remoteSettings = await fetchSettingsFromFirestore(userId);
        if (remoteSettings) {
          setSettings(remoteSettings);
          localStorage.setItem('workspaceSettings', JSON.stringify(remoteSettings));
        }
      }
    };
    loadSettings();
  }, [userId]);

  const handleSettingsChange = (name, value) => {
    const updatedSettings = { ...settings, [name]: value };
    setSettings(updatedSettings);
    localStorage.setItem('workspaceSettings', JSON.stringify(updatedSettings));
    saveSettingsToFirestore(userId, updatedSettings);
    onSettingsChange(name, value);
  };

  const handleReset = () => {
    setSettings(defaultSettings);
    localStorage.setItem('workspaceSettings', JSON.stringify(defaultSettings));
    saveSettingsToFirestore(userId, defaultSettings);
    for (const [name, value] of Object.entries(defaultSettings)) {
      onSettingsChange(name, value);
    }
  };

  const handleSave = () => {
    window.location.reload();
  };

  return (
    <div className="settings">
      <div className="settings-buttons">
        <button className="reset-button" onClick={handleReset}>
          Reset
        </button>
        <button className="save-button" onClick={handleSave}>
          Save
        </button>
      </div>
      <div className="setting-item">
        <span>Show Search Bar</span>
        <label className="toggle-switch">
          <input
            type="checkbox"
            name="showSearchBar"
            checked={settings.showSearchBar}
            onChange={(e) => handleSettingsChange(e.target.name, e.target.checked)}
          />
          <span className="slider"></span>
        </label>
      </div>
      <div className="setting-item">
        <span>Show Title</span>
        <label className="toggle-switch">
          <input
            type="checkbox"
            name="showTitle"
            checked={settings.showTitle}
            onChange={(e) => handleSettingsChange(e.target.name, e.target.checked)}
          />
          <span className="slider"></span>
        </label>
      </div>
      <div className="setting-item">
        <span>Show URL</span>
        <label className="toggle-switch">
          <input
            type="checkbox"
            name="showURL"
            checked={settings.showURL}
            onChange={(e) => handleSettingsChange(e.target.name, e.target.checked)}
          />
          <span className="slider"></span>
        </label>
      </div>
      <div className="setting-item">
        <span>Show Description</span>
        <label className="toggle-switch">
          <input
            type="checkbox"
            name="showDescription"
            checked={settings.showDescription}
            onChange={(e) => handleSettingsChange(e.target.name, e.target.checked)}
          />
          <span className="slider"></span>
        </label>
      </div>
      {/* <div className="setting-item">
        <span>Items Background Color</span>
        <input
          type="color"
          name="itemBackgroundColor"
          value={settings.itemBackgroundColor}
          onChange={(e) => handleSettingsChange(e.target.name, e.target.value)}
        />
      </div> */}
      {/* <div className="setting-item">
        <span>Page Background Color</span>
        <input
          type="color"
          name="pageBackgroundColor"
          value={settings.pageBackgroundColor}
          onChange={(e) => handleSettingsChange(e.target.name, e.target.value)}
        />
      </div> */}
    </div>
  );
};

const defaultSettings = {
  showTitle: true,
  showURL: false,
  showDescription: false,
  itemBackgroundColor: '#f9f9f9',
  pageBackgroundColor: '#ffffff'
};
