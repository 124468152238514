import React from 'react';
import '../css/Footer.css';

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className='bottom-line'></div>
      <div className="footer-content">
        <p>&copy; {currentYear} ZenWorkspace.io All rights reserved.</p>
        <ul>
          <li><a href="/">About</a></li>
          <li><a href="/blog">Blog</a></li>
          <li><a href="mailto:ofri.yehuda27@gmail.com">Contact</a></li>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
          <li><a href="https://www.linkedin.com/in/ofri-yehuda/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
        </ul>
      </div>
    </footer>
  );
};
