import React, { useState, useEffect } from 'react';
import '../css/AddComponentModal.css'; // Import the specific CSS
import { FiInfo } from 'react-icons/fi'; // Import the info icon

export const AddComponentModal = ({ isOpen, onClose, onSubmit, selectedComponents }) => {
  const [components, setComponents] = useState(selectedComponents || []);
  const [infoImage, setInfoImage] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setComponents(selectedComponents);
    }
  }, [isOpen, selectedComponents]);

  const handleToggleChange = (component) => {
    setComponents(prevComponents =>
      prevComponents.includes(component)
        ? prevComponents.filter(c => c !== component)
        : [...prevComponents, component]
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(components);
  };

  const handleInfoClick = (imageSrc) => {
    setInfoImage(imageSrc);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Select Add-ons</h2>
        <form onSubmit={handleSubmit}>
          <div className="toggle-group">

            <div className="toggle-item">
              <div>
                <FiInfo className="info-icon" onClick={() => handleInfoClick(require('../imgs/Todo.png'))} />
                <span>To-Do List</span>
              </div>
      
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    checked={components.includes('todos')}
                    onChange={() => handleToggleChange('todos')}
                  />
                  <span className="slider"></span>
                </label>
            </div>

            <div className="toggle-item">
              <div>
                <FiInfo className="info-icon" onClick={() => handleInfoClick(require('../imgs/Kanban.png'))} />
                <span>Kanban Board</span>
              </div>
             
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={components.includes('kanban')}
                  onChange={() => handleToggleChange('kanban')}
                />
                <span className="slider"></span>
              </label>
            </div>

            <div className="toggle-item">
                <div>
                  <FiInfo className="info-icon" onClick={() => handleInfoClick(require('../imgs/Notes.png'))} />
                  <span>Notes</span>
                </div>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      checked={components.includes('notes')}
                      onChange={() => handleToggleChange('notes')}
                    />
                    <span className="slider"></span>
                  </label>
              </div>

          </div>
          <div className="modal-actions">
            <button type="submit">Save</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
        {infoImage && (
          <div className="info-image-container">
            <img src={infoImage} alt="Component Info" />
            <button className="close-info" onClick={() => setInfoImage(null)}>Close</button>
          </div>
        )}
      </div>
    </div>
  );
};
