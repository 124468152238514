import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { getProjectNotes, addNoteToFirestore } from '../config/firefunctions';
import '../css/Notes.css'; // Assuming you have a CSS file for Notes

export const Notes = ({ userId, projectId }) => {
  const [notes, setNotes] = useState('');

  useEffect(() => {
    const fetchNotes = async () => {
      const projectNotes = await getProjectNotes(userId, projectId);
      setNotes(projectNotes);
    };
    fetchNotes();
  }, [userId, projectId]);

  const handleSaveNotes = async () => {
    await addNoteToFirestore(userId, projectId, notes);
  };

  const handleChange = (value) => {
    setNotes(value);
  };

  return (
    <div className="notes">
      <div className='top-notes'>
        <h3>Notes</h3>
        <button onClick={handleSaveNotes}>Save</button>
      </div>
      <ReactQuill
        value={notes}
        onChange={handleChange}
        modules={Notes.modules}
        formats={Notes.formats}
        theme="snow"
      />
    </div>
  );
};

// Quill modules and formats
Notes.modules = {
  toolbar: [
    [{ 'header': '1' }],
    [{ 'size': ['small', 'normal', 'large'] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link'],
  ],
};

Notes.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link',
];
