import React, { useState } from 'react';
import { PricingOption } from './PricingOption';
import '../css/Pricing.css';
import { useAuth } from '../config/AuthContext';
import { addToWaitingList } from '../config/firefunctions';
import { options } from '../config/PricingOptions';


export const Pricing = () => {
  const { user } = useAuth();
  const [selectedOption, setSelectedOption] = useState(options.findIndex(option => option.isRecommended));
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleEmailChange = (e) => setEmail(e.target.value);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailSubmit = async () => {
    if (isValidEmail(email)) {
      try {
        const userInfo = user ? { name: user.displayName, registeredEmail: user.email, userId: user.uid } : {};
        await addToWaitingList(email, userInfo);
        console.log('Email submitted:', email);
        setEmail('');
        setErrorMessage('');
        setSuccessMessage('Thank you! Your email has been submitted.');
      } catch (e) {
        console.error('Error submitting email:', e);
        setErrorMessage('Error submitting email. Please try again.');
        setSuccessMessage('');
      }
    } else {
      setErrorMessage('Please enter a valid email address.');
      setSuccessMessage('');
    }
  };

  return (
    <div>
      <div className="pricing">
        {options.map((option, index) => (
          <PricingOption 
            key={index}
            title={option.title}
            price={option.price}
            description={option.description}
            features={option.features}
            isRecommended={option.isRecommended}
            isSelected={index === selectedOption}
            onClick={() => setSelectedOption(index)}
          />
        ))}
      </div>
      <div className="action-area">
        {user && selectedOption === 0 ? (
          <div className="logged-in-message">
            You are using the free plan
          </div>
        ) : (
          selectedOption === 0 ? (
            <button className="get-started-btn" onClick={() => window.location.href='/login'}>Get Started</button>
          ) : (
            <div className="waitlist-form">
              <div>
                <input 
                  type="email" 
                  placeholder="Enter your email to join the waitlist" 
                  className="waitlist-input" 
                  value={email} 
                  onChange={handleEmailChange} 
                />
                <button className="submit-btn" onClick={handleEmailSubmit}>Submit</button>
              </div>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              {successMessage && <p className="success-message">{successMessage}</p>}
            </div>
          )
        )}
      </div>
    </div>
  );
};
