import { db } from './firebase';
import { collection, getDocs, updateDoc, deleteDoc, query, doc, setDoc, getDoc } from 'firebase/firestore';

// Function to get the count of workspaces for a user
const getUserWorkspaceCount = async (userId) => {
  const q = query(collection(db, `users/${userId}/workspaces`));
  const querySnapshot = await getDocs(q);
  return querySnapshot.size;
};

// Function to add a new workspace
export const addWorkspace = async (workspace) => {
  try {
    const userWorkspaceCount = await getUserWorkspaceCount(workspace.userId);
    const newWorkspaceNo = userWorkspaceCount + 1;
    const customDocId = `${workspace.title}_${newWorkspaceNo}`;
    const docRef = doc(db, `users/${workspace.userId}/workspaces`, customDocId);
    await setDoc(docRef, workspace);
    console.log('Document written with ID: ', customDocId);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};

// Function to get all workspaces for a specific user
export const getWorkspaces = async (userId) => {
  const workspaces = [];
  try {
    const q = query(collection(db, `users/${userId}/workspaces`));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      workspaces.push({ id: doc.id, ...doc.data() });
    });
  } catch (e) {
    console.error('Error getting documents: ', e);
  }
  return workspaces;
};

// Function to update a workspace
export const updateWorkspace = async (userId, id, updatedWorkspace) => {
  try {
    const workspaceDoc = doc(db, `users/${userId}/workspaces`, id);
    await updateDoc(workspaceDoc, updatedWorkspace);
  } catch (e) {
    console.error('Error updating document: ', e);
  }
};

// Function to delete a workspace
export const deleteWorkspace = async (userId, id) => {
  try {
    await deleteDoc(doc(db, `users/${userId}/workspaces`, id));
  } catch (e) {
    console.error('Error deleting document: ', e);
  }
};

// Function to add a workplace item to a specific workspace
export const addWorkplaceItem = async (userId, workspaceId, item) => {
  const workspaceRef = doc(db, `users/${userId}/workspaces`, workspaceId);
  const workspaceDoc = await getDoc(workspaceRef);
  const workspace = workspaceDoc.data();
  await updateDoc(workspaceRef, {
    items: [...(workspace.items || []), item]
  });
};

// Function to delete a workplace item from a specific workspace
export const deleteWorkplaceItem = async (userId, workspaceId, itemId) => {
  const workspaceRef = doc(db, `users/${userId}/workspaces`, workspaceId);
  const workspaceDoc = await getDoc(workspaceRef);
  const workspace = workspaceDoc.data();
  const updatedItems = (workspace.items || []).filter(item => item.id !== itemId);
  await updateDoc(workspaceRef, { items: updatedItems });
};

// Function to update a workplace item
export const updateWorkplaceItem = async (userId, workspaceId, itemId, updatedItem) => {
  try {
    const workspaceRef = doc(db, `users/${userId}/workspaces`, workspaceId);
    const workspaceDoc = await getDoc(workspaceRef);

    if (!workspaceDoc.exists()) {
      console.error(`Workspace with ID ${workspaceId} does not exist`);
      throw new Error('Workspace does not exist');
    }

    const workspace = workspaceDoc.data();

    if (!workspace.items) {
      workspace.items = [];
    }

    const updatedItems = workspace.items.map(item =>
      item.id === itemId ? { ...item, ...updatedItem } : item
    );

    // Ensure no fields in updatedItems have undefined values
    const sanitizedItems = updatedItems.map(item => {
      const sanitizedItem = {};
      for (const key in item) {
        if (item[key] !== undefined) {
          sanitizedItem[key] = item[key];
        }
      }
      return sanitizedItem;
    });

    await updateDoc(workspaceRef, { items: sanitizedItems });
    console.log('Item updated successfully');
  } catch (error) {
    console.error('Error updating item:', error);
    throw error;
  }
};


// Function to save settings to Firestore
export const fetchSettingsFromFirestore = async (userId) => {
  try {
    const docRef = doc(db, 'users', userId, 'settings', 'workspaceSettings');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching settings:', error);
  }
};

export const saveSettingsToFirestore = async (userId, settings) => {
  try {
    const docRef = doc(db, 'users', userId, 'settings', 'workspaceSettings');
    await setDoc(docRef, settings, { merge: true });
  } catch (error) {
    console.error('Error saving settings:', error);
  }
};

// Function to add an email to the waiting list
export const addToWaitingList = async (email, userInfo = {}) => {
  try {
    const docRef = doc(collection(db, 'waitingList'));
    await setDoc(docRef, { email, ...userInfo });
    console.log('Email added to waiting list:', email);
  } catch (e) {
    console.error('Error adding email to waiting list:', e);
  }
};

// Function to get the user's plan
export const getUserPlan = async (userId) => {
  try {
    const docRef = doc(db, 'users', userId, 'settings', 'workspaceSettings');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data().Plan || 'Free';
    } else {
      return 'Free';
    }
  } catch (error) {
    console.error('Error fetching user plan:', error);
    return 'Free';
  }
};

// Function to add a new project
export const addProjectToFirestore = async (userId, project) => {
  if (!userId || userId === 'demo_user') {
    console.error('Error adding project: userId is null or is demo_user');
    return null;
  }
  try {
    const projectRef = doc(collection(db, `users/${userId}/projects`));
    await setDoc(projectRef, project);
    return projectRef.id;
  } catch (e) {
    console.error('Error adding project: ', e);
  }
};

// Function to get all projects for a specific user
export const getProjects = async (userId) => {
  if (!userId || userId === 'demo_user') {
    console.error('Error getting projects: userId is null or is demo_user');
    return [];
  }
  const projects = [];
  try {
    const q = query(collection(db, `users/${userId}/projects`));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      projects.push({ id: doc.id, ...doc.data() });
    });
  } catch (e) {
    console.error('Error getting projects: ', e);
  }
  return projects;
};

// Function to delete a project
export const deleteProjectFromFirestore = async (userId, projectId) => {
  if (!userId || userId === 'demo_user') {
    console.error('Error deleting project: userId is null or is demo_user');
    return;
  }
  try {
    const projectRef = doc(db, `users/${userId}/projects/${projectId}`);
    await deleteDoc(projectRef);
  } catch (e) {
    console.error('Error deleting project: ', e);
  }
};

// Function to update project components
export const updateProjectComponents = async (userId, projectId, components) => {
  if (!userId || userId === 'demo_user') {
    console.error('Error updating project components: userId is null or is demo_user');
    return;
  }
  try {
    const projectRef = doc(db, `users/${userId}/projects/${projectId}`);
    await updateDoc(projectRef, { components });
  } catch (e) {
    console.error('Error updating project components: ', e);
  }
};

// Function to get todos for a project
export const getProjectTodos = async (userId, projectId) => {
  const todos = [];
  try {
    const q = query(collection(db, `users/${userId}/projects/${projectId}/todos`));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      todos.push({ id: doc.id, ...doc.data() });
    });
  } catch (e) {
    console.error('Error getting todos: ', e);
  }
  return todos;
};

// Function to add a todo to a project
export const addTodoToFirestore = async (userId, projectId, todoText) => {
  try {
    const todoRef = doc(collection(db, `users/${userId}/projects/${projectId}/todos`));
    await setDoc(todoRef, { text: todoText });
  } catch (e) {
    console.error('Error adding todo: ', e);
  }
};

// Function to delete a todo from a project
export const deleteTodoFromFirestore = async (userId, projectId, todoId) => {
  try {
    const todoRef = doc(db, `users/${userId}/projects/${projectId}/todos/${todoId}`);
    await deleteDoc(todoRef);
  } catch (e) {
    console.error('Error deleting todo:', e);
  }
};

// Function to get notes for a project
export const getProjectNotes = async (userId, projectId) => {
  try {
    const notesRef = doc(db, `users/${userId}/projects/${projectId}/notes`, 'notes');
    const docSnap = await getDoc(notesRef);
    if (docSnap.exists()) {
      return docSnap.data().text || '';
    } else {
      return '';
    }
  } catch (e) {
    console.error('Error getting notes: ', e);
  }
};

// Function to add a note to a project
export const addNoteToFirestore = async (userId, projectId, noteText) => {
  try {
    const notesRef = doc(db, `users/${userId}/projects/${projectId}/notes`, 'notes');
    await setDoc(notesRef, { text: noteText }, { merge: true });
  } catch (e) {
    console.error('Error adding note: ', e);
  }
};

// Function to get tasks for a project
export const getProjectTasks = async (userId, projectId) => {
  const tasks = {
    todo: [],
    inProgress: [],
    done: []
  };
  try {
    const q = query(collection(db, `users/${userId}/projects/${projectId}/tasks`));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      tasks[doc.id] = doc.data().tasks || [];
    });
  } catch (e) {
    console.error('Error getting tasks: ', e);
  }
  return tasks;
};

// Function to add a task to a specific column in a project
export const addTaskToFirestore = async (userId, projectId, column, task) => {
  try {
    const taskRef = doc(db, `users/${userId}/projects/${projectId}/tasks`, column);
    const taskDoc = await getDoc(taskRef);
    const tasks = taskDoc.exists() ? taskDoc.data().tasks : [];
    await setDoc(taskRef, { tasks: [...tasks, task] }, { merge: true });
  } catch (e) {
    console.error('Error adding task: ', e);
  }
};

// Function to update tasks for a project
export const updateProjectTasks = async (userId, projectId, tasks) => {
  try {
    for (const [column, taskList] of Object.entries(tasks)) {
      const tasksRef = doc(db, `users/${userId}/projects/${projectId}/tasks`, column);
      await setDoc(tasksRef, { tasks: taskList }, { merge: true });
    }
  } catch (e) {
    console.error('Error updating tasks:', e);
  }
};

// Function to delete a task from a specific column in a project
export const deleteTaskFromFirestore = async (userId, projectId, column, taskId) => {
  try {
    const tasksRef = doc(db, `users/${userId}/projects/${projectId}/tasks`, column);
    const taskDoc = await getDoc(tasksRef);
    if (taskDoc.exists()) {
      const tasks = taskDoc.data().tasks.filter(task => task.id !== taskId);
      await setDoc(tasksRef, { tasks }, { merge: true });
    }
  } catch (e) {
    console.error('Error deleting task: ', e);
  }
};