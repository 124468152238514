// src/components/Icon.js
import React from 'react';
import { IconContext } from 'react-icons';

const Icon = ({ icon: IconComponent, size, color, className, style, title, onClick, onMouseEnter, onMouseLeave }) => {
  return (
    <IconContext.Provider value={{ size, color, className, style, title }}>
      <div onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <IconComponent />
      </div>
    </IconContext.Provider>
  );
};

export default Icon;
