export const options = [
    {
      title: 'Basic',
      price: 'Free',
      description: 'Great for individuals starting out',
      features: ['Up to 3 workspaces', 'Up to 5 items per workspace',
        'Built in Apps & Custom URLs', 'Workspace customization',
        'Project management', 'Cloud sync'],
      isRecommended: false
    },
    {
      title: 'Pro',
      price: '$4/month',
      description: 'Perfect for multiproject professionals',
      features: ['Up to 7 workspaces', 'Unlimited items', 'Plus all basic features'],
      isRecommended: true
    },
    {
      title: 'Super user',
      price: '$10/month',
      description: 'Super users who can do anything',
      features: ['Unlimited workspaces', 'Unlimited items in each workspace', 'Plus all Pro features'],
      isRecommended: false
    }
  ];

  export const planLimits = {
    Free: { maxWorkspaces: 3, maxItemsPerWorkspace: 5, maxProjects: 2 },
    Pro: { maxWorkspaces: 7, maxItemsPerWorkspace: Infinity, maxProjects: Infinity },
    SuperUser: { maxWorkspaces: Infinity, maxItemsPerWorkspace: Infinity, maxProjects: Infinity }
  };
  