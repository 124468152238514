import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../css/AddItemModal.css';
import { fetchmeta, fetchIcon, fetchIconForApp } from '../config/fetchmeta';
import { apps } from '../config/CommonApps';

export const AddItemModal = ({ isOpen, onClose, onSubmit }) => {
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [useCustomUrl, setUseCustomUrl] = useState(false); // Changed default to false to start with "Choose from apps"
  const [selectedApp, setSelectedApp] = useState('');
  const [appIcons, setAppIcons] = useState({});
  const [selectedAppIcon, setSelectedAppIcon] = useState('');
  const [homepageUrl, setHomepageUrl] = useState(''); // Added state for homepage URL
  const [openChoice, setOpenChoice] = useState('website'); // State to manage choice between app and website
  const [isFetching, setIsFetching] = useState(false); // State to manage fetching status

  useEffect(() => {
    const fetchAppIcons = async () => {
      const icons = {};
      for (const app of apps) {
        const iconUrl = fetchIconForApp(app.web);
        icons[app.name] = iconUrl;
      }
      setAppIcons(icons);
    };

    fetchAppIcons();
  }, []);

  useEffect(() => {
    const fetchMetadata = async () => {
      if (url && useCustomUrl) {
        setIsFetching(true);
        try {
          const fullUrl = !url.startsWith('http://') && !url.startsWith('https://') ? `https://${url}` : url;
          const metadata = await fetchmeta(fullUrl);
          setTitle(metadata.title);
        } catch (error) {
          console.error('Error fetching metadata:', error);
        } finally {
          setIsFetching(false);
        }
      }
    };

    const delayFetchMetadata = setTimeout(fetchMetadata, 1000);

    return () => clearTimeout(delayFetchMetadata);
  }, [url, useCustomUrl]);

  const handleAppChange = (selectedOption) => {
    const app = apps.find(app => app.name === selectedOption.value);
    if (app) {
      setSelectedApp(app.name);
      setTitle(app.name);
      setUrl(app.protocol);
      setHomepageUrl(app.web); // Set homepage URL
      setSelectedAppIcon(appIcons[app.name]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let finalUrl = url;

    if (useCustomUrl) {
      if (!finalUrl.startsWith('http://') && !finalUrl.startsWith('https://')) {
        finalUrl = `https://${finalUrl}`;
      }
      onSubmit({ title, url: finalUrl, icon: fetchIcon(finalUrl), isCustomUrl: true, homepageUrl: finalUrl, commonapp: false });
    } else {
      onSubmit({ title, url: finalUrl, icon: selectedAppIcon, isCustomUrl: false, homepageUrl, commonapp: true, openChoice });
    }

    handleClose(); // Clear form and close modal
  };

  const handleClose = () => {
    setTitle('');
    setUrl('');
    setSelectedApp('');
    setSelectedAppIcon('');
    setHomepageUrl('');
    setUseCustomUrl(false); // Reset to app selection after submission
    onClose();
  };

  if (!isOpen) return null;

  const appOptions = apps.map(app => ({
    value: app.name,
    label: (
      <div className="app-option">
        {appIcons[app.name] && <img src={appIcons[app.name]} alt={app.name} className="app-icon" />}
        {app.name}
      </div>
    )
  }));

  return (
    <div className="modal">
      <div className="modal-content new-item">
        <span className="close" onClick={handleClose}>&times;</span>
        <h2>Add New Item</h2>
        <span>Add an existing <b>app</b> or <b>custom URL</b></span>
        <form onSubmit={handleSubmit}>
          <div className="button-group">
            <button
              type="button"
              className={!useCustomUrl ? 'active' : ''}
              onClick={() => setUseCustomUrl(false)}
            >
              App
            </button>
            <button
              type="button"
              className={useCustomUrl ? 'active' : ''}
              onClick={() => setUseCustomUrl(true)}
            >
              URL
            </button>
          </div>

          {!useCustomUrl ? (
            <>
              <label>
                <Select
                  className="styled-select"
                  value={appOptions.find(option => option.value === selectedApp)}
                  onChange={handleAppChange}
                  options={appOptions}
                />
              </label>
              {selectedApp && (
                <div className='radio-btns'>
                  <label>
                    <input
                      type="radio"
                      value="app"
                      checked={openChoice === 'app'}
                      onChange={() => setOpenChoice('app')}
                    />
                    Open App
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="website"
                      checked={openChoice === 'website'}
                      onChange={() => setOpenChoice('website')}
                    />
                    Open Website
                  </label>
                  <span className='radio-text'>It's recommended to choose app if you have it already installed on your machine</span>
                </div>
              )}
            </>
          ) : (
            <>
              <label>
                <input
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  required
                  placeholder='URL'
                />
              </label>
              <label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  placeholder='Title'
                  disabled={isFetching}
                />
              </label>
            </>
          )}

          <button type="submit" className={`submit ${!title || !url ? 'disabled' : ''}`} disabled={!title || !url}>Add Item</button>
        </form>
      </div>
    </div>
  );
};
