import React, { useState, useEffect } from 'react';
import '../css/WorkspaceItem.css';
import { FaRegTrashAlt, FaRegEdit, FaLink, FaDesktop } from 'react-icons/fa';
import Icon from './Icon';
import { updateWorkplaceItem } from '../config/firefunctions';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase'; // Make sure this import points to your firebase config
import zenlogoCircle from '../imgs/zenlogoCircle.png';

export const WorkspaceItem = ({ id, title, url, homepageUrl, description, icon, onDelete, isEditMode, userId, workspaceId, refetchWorkspaces, settings, commonApp: propCommonApp, openChoice: propOpenChoice, isDemo }) => {
  const [hover, setHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editTitle, setEditTitle] = useState(title);
  const [editUrl, setEditUrl] = useState(url);
  const [editHomepageUrl, setEditHomepageUrl] = useState(homepageUrl);
  const [editDescription, setEditDescription] = useState(description);
  const [editIcon, setEditIcon] = useState(icon);
  const [currentSettings, setCurrentSettings] = useState(settings);
  const [commonApp, setCommonApp] = useState(propCommonApp || null); // Use propCommonApp if provided
  const [openChoice, setOpenChoice] = useState(propOpenChoice || 'website'); // Use propOpenChoice if provided

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedSettings = JSON.parse(localStorage.getItem('workspaceSettings'));
      setCurrentSettings(updatedSettings || settings);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [settings]);

  useEffect(() => {
    const updatedSettings = JSON.parse(localStorage.getItem('workspaceSettings'));
    setCurrentSettings(updatedSettings || settings);
  }, []);

  // Fetch commonapp attribute and openChoice
  useEffect(() => {
    if (!isDemo && !propCommonApp) {
      const fetchCommonApp = async () => {
        try {
          const workspaceRef = doc(db, `users/${userId}/workspaces`, workspaceId);
          const workspaceDoc = await getDoc(workspaceRef);
          if (workspaceDoc.exists()) {
            const workspaceData = workspaceDoc.data();
            const item = workspaceData.items.find(item => item.id === id);
            if (item) {
              setCommonApp(item.commonapp);
              setOpenChoice(item.openChoice || 'website'); // Set openChoice to stored value or default to 'website'
            }
          } else {
            console.log('Workspace not found');
          }
        } catch (error) {
          console.error('Error fetching commonapp attribute:', error);
        }
      };

      fetchCommonApp();
    }
  }, [userId, workspaceId, id, propCommonApp, isDemo]);

  const handleItemClick = (e) => {
    if (isEditMode) return; // Do nothing if in edit mode
    e.stopPropagation(); // Prevent multiple clicks
    const urlToOpen = commonApp && openChoice === 'app' ? url : homepageUrl ? homepageUrl : url;
    window.open(urlToOpen, '_blank', 'noopener,noreferrer');
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    try {
      // Create an updated item object, omitting undefined fields
      const updatedItem = {};
      if (!commonApp) {
        if (editTitle !== undefined) updatedItem.title = editTitle;
        if (editUrl !== undefined) updatedItem.url = editUrl;
        if (editHomepageUrl !== undefined) updatedItem.homepageUrl = editHomepageUrl;
        if (editDescription !== undefined) updatedItem.description = editDescription;
      }
      if (editIcon !== undefined) updatedItem.icon = editIcon;

      if (commonApp !== null) {
        updatedItem.openChoice = openChoice; // Add the choice to the updated item
      }

      await updateWorkplaceItem(userId, workspaceId, id, updatedItem);
      setIsEditing(false);
      refetchWorkspaces(); // Refetch workspaces after saving
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditTitle(title);
    setEditUrl(url);
    setEditHomepageUrl(homepageUrl);
    setEditDescription(description);
    setEditIcon(icon);
  };

  return (
    <div className="workspace-item">
      {isEditing ? (
        <div className="workspace-item-edit">
          {!commonApp && (
            <>
              <input
                type="text"
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
                placeholder="Title"
              />
              <input
                type="text"
                value={editDescription}
                onChange={(e) => setEditDescription(e.target.value)}
                placeholder="Description"
              />
              <input
                type="url"
                value={editUrl}
                onChange={(e) => setEditUrl(e.target.value)}
                placeholder="URL"
              />
            </>
          )}
          {commonApp && (
            <div>
              <label>
                <input
                  type="radio"
                  value="app"
                  checked={openChoice === 'app'}
                  onChange={() => setOpenChoice('app')}
                />
                Open App
              </label>
              <label>
                <input
                  type="radio"
                  value="website"
                  checked={openChoice === 'website'}
                  onChange={() => setOpenChoice('website')}
                />
                Open Website
              </label>
            </div>
          )}
          <button onClick={handleSaveClick}>Save</button>
          <button onClick={handleCancelClick}>Cancel</button>
        </div>
      ) : (
        <>
          <div className="workspace-content" onClick={handleItemClick} style={{ cursor: isEditMode ? 'default' : 'pointer' }}>
            <img
              src={icon}
              alt={title}
              className="link-preview-image"
              onError={(e) => e.target.src = zenlogoCircle}
            />
            {commonApp ? (
              openChoice === 'app' ? (
                <FaDesktop className="type-icon" />
              ) : (
                <FaLink className="type-icon" />
              )
            ) : (
              <FaLink className="type-icon" />
            )}
            <div className="workspace-info">
              {currentSettings.showTitle && <h3>{title}</h3>}
              {currentSettings.showDescription && description && <p>{description}</p>}
              {currentSettings.showURL && <a href={url} onClick={(e) => e.preventDefault()} target="_blank" rel="noopener noreferrer" onMouseDown={e => e.stopPropagation()}>{url}</a>}
            </div>
          </div>
          {isEditMode && (
            <div className="workspace-item-actions">
              <button
                className="edit-btn"
                onClick={handleEditClick}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <Icon icon={FaRegEdit} color={hover ? "darkgrey" : "grey"} />
              </button>
              <button
                className="delete-btn"
                onClick={onDelete}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <Icon icon={FaRegTrashAlt} color={hover ? "darkred" : "red"} />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
