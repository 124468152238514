// src/config/firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
const REACT_APP_FIREBASE_KEY = process.env.REACT_APP_FIREBASE_KEY;

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_KEY,
  authDomain: "workspaces-56ac5.firebaseapp.com",
  projectId: "workspaces-56ac5",
  storageBucket: "workspaces-56ac5.appspot.com",
  messagingSenderId: "147027488044",
  appId: "1:147027488044:web:d27acff2c5280c5e3d9089",
  measurementId: "G-C0Y63SCKL7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { db, auth, googleProvider };
