import React, { useState } from 'react';
import '../css/Search.css';
import { CiSearch } from 'react-icons/ci';
import Icon from './Icon'; // Make sure to import the Icon component

export const Search = () => {
  const [query, setQuery] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim()) {
      window.open(`https://www.google.com/search?q=${encodeURIComponent(query)}`, '_blank');
    }
  };

  return (
    <form className="search-form" onSubmit={handleSearch}>
      <input
        type="text"
        placeholder="Search with Google"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <button type="submit">
        <Icon icon={CiSearch} size="1.5em" color="black"/>
      </button>
    </form>
  );
};
