import React, { useState, useEffect, useRef } from 'react';
import { Login } from './Login';
import { auth } from '../config/firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { WorkspaceSettings } from './WorkspaceSettings';
import '../css/SideMenu.css';
import { PiBrowsersLight } from 'react-icons/pi';
import { IoSettingsOutline, IoPersonOutline } from 'react-icons/io5';
import { CiLogout } from 'react-icons/ci';
import { IoMdClose } from "react-icons/io";
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";
import Icon from './Icon';
import { getUserPlan, fetchSettingsFromFirestore, saveSettingsToFirestore } from '../config/firefunctions';
import {BookmarkButton} from './BookmarkButton';

export const SideMenu = ({ isOpen, toggleMenu }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [userPlan, setUserPlan] = useState('');
  const [isFetchingPlan, setIsFetchingPlan] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [settings, setSettings] = useState({
    showTitle: true,
    showURL: false,
    backgroundColor: '#ffffff',
    showDescription: false,
    showSearchBar: false,
    itemBackgroundColor: '#ffffff',
    pageBackgroundColor: '#ffffff'
  });
  const [darkMode, setDarkMode] = useState(() => localStorage.getItem('darkMode') === 'true');

  const menuRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUser(user);
        const plan = await getUserPlan(user.uid);
        setUserPlan(plan);
        setIsFetchingPlan(false);

        const userSettings = await fetchSettingsFromFirestore(user.uid);
        if (userSettings) {
          setSettings(userSettings);
          setDarkMode(userSettings.darkMode);
          applyDarkMode(userSettings.darkMode);
        }
      } else {
        setIsLoggedIn(false);
        setUser(null);
        setIsFetchingPlan(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        toggleMenu();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggleMenu]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsLoggedIn(false);
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleSettingsChange = (name, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value
    }));
  };

  const applyDarkMode = (isDarkMode) => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  };

  const toggleDarkMode = async () => {
    setDarkMode((prevDarkMode) => {
      const newDarkMode = !prevDarkMode;
      applyDarkMode(newDarkMode);
      localStorage.setItem('darkMode', newDarkMode);
      if (user) {
        saveSettingsToFirestore(user.uid, { darkMode: newDarkMode });
      }
      return newDarkMode;
    });
  };

  return (
    <div ref={menuRef} className={`side-menu ${isOpen ? 'open' : ''}`}>
      
      <button onClick={toggleDarkMode} className='side-menu-mode-btn'>
        {darkMode ? (
          <Icon icon={MdOutlineLightMode} size="1.5em" color="black" className={"settings-btn"} />
        ) : (
          <Icon icon={MdOutlineDarkMode} size="1.5em" color="black" className={"settings-btn"} />)}
      </button>
      
      <button className="close-button" onClick={toggleMenu}>
        <Icon icon={IoMdClose} size="1.5em" color="black" />
      </button>
      
      {isLoggedIn ? (
        <div>
          <div className="user-info">
            <img src={user.photoURL} alt="User profile" className="user-avatar" />
            <p>{user.displayName}</p>
            {isFetchingPlan ? (
              <p>Loading...</p>
            ) : (
              <p className="user-plan" onClick={() => window.location.href = '/pricing'}>
                {userPlan} Plan
              </p>
            )}
          </div>
          <ul className="menu-items">
            <li>
              <a href="/MyWorkSpaces">
                <Icon icon={PiBrowsersLight} size="1.5em" color="black" className={"settings-btn"} /> My WorkSpaces
              </a>
            </li>
            <li>
              <button onClick={() => setShowSettings(!showSettings)}>
                <Icon icon={IoSettingsOutline} size="1.5em" color="black" className={"settings-btn"} /> WorkSpace Settings
              </button>
            </li>
            {showSettings && (
              <WorkspaceSettings userId={user.uid} settings={settings} onSettingsChange={handleSettingsChange} />
            )}
            <li>
              <BookmarkButton />
            </li>
            <li>
              <a href="/" onClick={handleLogout}>
                <Icon icon={CiLogout} size="1.5em" color="black" className={"settings-btn"} /> Logout
              </a>
            </li>
          </ul>
        </div>
      ) : (
        <Login onLogin={() => setIsLoggedIn(true)} />
      )}
    </div>
  );
};
