// src/comps/WorkspaceModal.js
import React, { useState } from 'react';
import '../css/WorkspaceModal.css';

export const WorkspaceModal = ({ isOpen, onClose, onSubmit }) => {
  const [title, setTitle] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ title });
    setTitle('');
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Add New Workspace</h2>
        <form onSubmit={handleSubmit}>
          <label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              placeholder='Title'
            />
          </label>
          <div className="modal-actions">
            <button type="submit">Add Workspace</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

