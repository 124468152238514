import React from 'react';
import { WorkspaceItem } from './WorkspaceItem';
import '../css/AllApps.css'; // Assuming you want to style it separately

export const AllApps = ({ workspaces, onDeleteItem, userId, settings }) => {
  const allItems = workspaces.flatMap(workspace => 
    workspace.items.map(item => ({
      ...item,
      workspaceId: workspace.id // Add workspaceId to each item
    }))
  );

  return (
    <div className="all-apps workspace-container">
      {allItems.length === 0 ? (
        <div className="no-apps">
          <h2>No Apps Available</h2>
          <p>Add items to your workspaces to see them here.</p>
        </div>
      ) : (
        <div className="app-list">
          {allItems.map((item) => (
            <WorkspaceItem
              key={item.id}
              id={item.id}
              title={item.title}
              url={item.url}
              homepageUrl={item.homepageUrl}
              description={item.description}
              icon={item.icon}
              onDelete={() => onDeleteItem(item.workspaceId, item.id)}
              userId={userId}
              workspaceId={item.workspaceId}
              refetchWorkspaces={() => {}}
              settings={settings}
              openChoice={item.openChoice} // Pass openChoice
              commonApp={item.commonapp} // Pass commonApp
            />
          ))}
        </div>
      )}
    </div>
  );
};
