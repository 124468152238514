import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { getProjectTasks, addTaskToFirestore, updateProjectTasks, deleteTaskFromFirestore } from '../config/firefunctions';
import { FaEdit } from 'react-icons/fa';
import '../css/KanbanBoard.css';

export const KanbanBoard = ({ userId, projectId }) => {
  const [tasks, setTasks] = useState({
    todo: [],
    inProgress: [],
    done: []
  });
  const [newTaskContent, setNewTaskContent] = useState('');
  const [showDeleteIcons, setShowDeleteIcons] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchTasks = async () => {
      const projectTasks = await getProjectTasks(userId, projectId);
      if (isMounted) {
        setTasks(projectTasks || {
          todo: [],
          inProgress: [],
          done: []
        });
      }
    };
    fetchTasks();

    return () => {
      isMounted = false;
    };
  }, [userId, projectId]);

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // If destination is null or source and destination are the same, do nothing
    if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
      return;
    }

    const sourceColumn = Array.from(tasks[source.droppableId]);
    const destColumn = Array.from(tasks[destination.droppableId]);
    const [movedTask] = sourceColumn.splice(source.index, 1);
    destColumn.splice(destination.index, 0, movedTask);

    const newTasks = {
      ...tasks,
      [source.droppableId]: sourceColumn,
      [destination.droppableId]: destColumn
    };

    setTasks(newTasks);
    await updateProjectTasks(userId, projectId, newTasks);
  };

  const handleAddTask = async () => {
    if (newTaskContent.trim() === '') return;

    const newTask = { id: `task-${Date.now()}`, content: newTaskContent };
    const newTasks = {
      ...tasks,
      todo: [...tasks.todo, newTask]
    };

    setTasks(newTasks);
    await addTaskToFirestore(userId, projectId, 'todo', newTask);
    setNewTaskContent('');
  };

  const handleDeleteTask = async (column, taskId) => {
    const newTasks = {
      ...tasks,
      [column]: tasks[column].filter(task => task.id !== taskId)
    };

    setTasks(newTasks);
    await deleteTaskFromFirestore(userId, projectId, column, taskId);
  };

  return (
    <div>
      <div className='top-kanban'>
        <h3>Kanban Board</h3>

        <div className='kanban-buttons'>
          <button onClick={handleAddTask}>Add Task</button>

          <button onClick={() => setShowDeleteIcons(!showDeleteIcons)} className="edit-mode-btn" >
          <FaEdit />
          </button>
        </div>
      </div>
      <div className="add-task-form">
        <input 
          type="text" 
          value={newTaskContent} 
          onChange={(e) => setNewTaskContent(e.target.value)} 
          placeholder="Add a new task" 
        />
      
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="kanban-board">
          {['todo', 'inProgress', 'done'].map((status) => (
            <Droppable droppableId={status} key={status}>
              {(provided) => (
                <div
                  className="kanban-column"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <h3>{status.toUpperCase()}</h3>
                  {tasks[status].map((task, index) => (
                    <Draggable key={task.id} draggableId={task.id} index={index}>
                      {(provided) => (
                        <div
                          className="kanban-task"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {task.content}
                          {showDeleteIcons && (
                            <button
                              className="delete-task-btn"
                              onClick={() => handleDeleteTask(status, task.id)}
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
    </div>
  );
};
