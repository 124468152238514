import React from 'react';
import '../css/PricingOption.css';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

export const PricingOption = ({ title, price, description, features, isRecommended, isSelected, onClick }) => {
  return (
    <div 
      className={`pricing-option ${isRecommended ? 'recommended' : ''} ${isSelected ? 'selected' : ''}`} 
      onClick={onClick}
    >
      {isRecommended && <div className="ribbon">Recommended</div>}
      <h2>{title}</h2>
      <h3>{price}</h3>
      <p><b>{description}</b></p>
      <ul>
        {features.map((feature, index) => (
          <li key={index}><IoMdCheckmarkCircleOutline className="feature-icon" /> {feature}</li>
        ))}
      </ul>
    </div>
  );
};
