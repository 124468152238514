import React, { useState } from 'react';
import '../css/AddWorkSpaceButton.css';
import { WorkspaceModal } from './WorkspaceModal';

export const AddWorkSpaceButton = ({ onAdd }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAddWorkspace = async (workspace) => {
    await onAdd(workspace);
    handleCloseModal();
  };

  return (
    <>
      <button className="add-workspace-button" onClick={handleOpenModal}>
        Add a Workspace
      </button>
      <WorkspaceModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleAddWorkspace}
      />
    </>
  );
};
