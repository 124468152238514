// src/comps/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../css/NotFound.css'; // Make sure to create this CSS file for styling

export const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <Link to="/" className="back-home-link">Go back to Home</Link>
    </div>
  );
};
