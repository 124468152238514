import screens from '../imgs/Screens.gif';
import '../css/ScreensGif.css';

export const ScreensGif = () => {
  return (
    <div className="screens-gif">
      <h1>Match your Workspace for every need</h1>
      <img src={screens} alt="screens gif" className='screens' />
    </div>
  );
};
