import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// Import components
import { Home } from './comps/Home';
import { AppHome } from './comps/AppHome';
import { Nav } from './comps/Nav';
import { Footer } from './comps/Footer';
import { AuthProvider } from './config/AuthContext';
import { LoginPage } from './comps/LoginPage';
import { Blog } from './comps/Blog';
import { BlogPost } from './comps/BlogPost';
import { PrivacyPolicy } from './comps/PrivacyPolicy';
import { Pricing } from './comps/Pricing';
import { LiveDemo } from './comps/LiveDemo';
import { NotFound } from './comps/NotFound';
import { Admin } from './comps/Admin';
import Hotjar from '@hotjar/browser';

function App() {
  useEffect(() => {
    const siteId = 5017563;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);

    // Check for dark mode preference in local storage
    const darkMode = localStorage.getItem('darkMode') === 'true';
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Nav />
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/MyWorkSpaces" element={<AppHome />} />
            <Route path="/Login" element={<LoginPage />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/blog/:name" element={<BlogPost />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/LiveDemo" element={<LiveDemo />} />
            <Route path="/admin" element={<Admin />} /> 
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;