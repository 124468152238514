import React, { useState } from 'react';
import { WorkspaceItem } from './WorkspaceItem';
import { AddButton } from './AddButton';
import { AddItemModal } from './AddItemModal';
import '../css/Workspace.css';
import { fetchmeta, fetchIconForApp } from '../config/fetchmeta';
import { apps } from '../config/CommonApps';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { IoIosArrowDown } from "react-icons/io";
import zenlogoCircle from '../imgs/zenlogoCircle.png';
import { updateWorkspace } from '../config/firefunctions'; // Assuming you have this function to update the workspace

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Are you sure you want to proceed?</h2>
        <div className="modal-actions">
          <button className="confirm-btn" onClick={onConfirm}>Yes</button>
          <button className="cancel-btn" onClick={onClose}>No</button>
        </div>
      </div>
    </div>
  );
};

export const Workspace = ({ userId, workspace, settings, onDelete, onAddItem, onDeleteItem, refetchWorkspaces, isDemo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [popUpBlocked, setPopUpBlocked] = useState(false);
  const [workspaceTitle, setWorkspaceTitle] = useState(workspace.title);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleAddItem = async (newItem) => {
    try {
      let itemWithMetadata = { id: `item_${Date.now()}`, ...newItem };

      if (newItem.isCustomUrl) {
        const metadata = await fetchmeta(newItem.url);
        itemWithMetadata = {
          ...itemWithMetadata,
          description: metadata.description || '',
          icon: metadata.icon || zenlogoCircle,
        };
      } else {
        const app = apps.find(app => app.name === newItem.title);
        if (app) {
          const iconUrl = fetchIconForApp(app.web);
          itemWithMetadata = {
            ...itemWithMetadata,
            icon: iconUrl || zenlogoCircle,
            homepageUrl: app.web, // Add homepage URL to the item
          };
        }
      }

      onAddItem(itemWithMetadata);
    } catch (error) {
      console.error("Error fetching metadata:", error);
      onAddItem({ ...newItem, icon: zenlogoCircle, homepageUrl: newItem.url }); // Fallback to zenlogoCircle and URL on error
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const toggleEditMode = () => setIsEditMode(!isEditMode);
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const handleWorkspaceTitleChange = (e) => {
    setWorkspaceTitle(e.target.value);
  };

  const handleWorkspaceTitleBlur = async () => {
    if (workspaceTitle !== workspace.title) {
      await updateWorkspace(userId, workspace.id, { title: workspaceTitle });
      refetchWorkspaces();
    }
  };

  const openAllItems = async () => {
    if (isEditMode) return; // Do nothing if in edit mode
    setPopUpBlocked(false);
    let blocked = false;
    for (const [index, item] of workspace.items.entries()) {
      const urlToOpen = item.openChoice === 'app' ? item.url : item.homepageUrl ? item.homepageUrl : item.url;
      const newWindow = window.open(urlToOpen, '_blank', 'noopener,noreferrer');
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        blocked = true;
      }
      await new Promise(resolve => setTimeout(resolve, index * 100));
    }
    setPopUpBlocked(blocked);
  };

  const openItem = (item) => {
    if (isEditMode) return; // Do nothing if in edit mode
    const urlToOpen = item.openChoice === 'app' ? item.url : item.homepageUrl ? item.homepageUrl : item.url;
    window.open(urlToOpen, '_blank', 'noopener,noreferrer');
  };

  const hasManyItems = workspace.items && workspace.items.length > 3;

  return (
    <div className="workspace-container">
      <div className="workspace-header">
        {isEditMode ? (
          <input
            type="text"
            value={workspaceTitle}
            onChange={handleWorkspaceTitleChange}
            onBlur={handleWorkspaceTitleBlur}
            className="workspace-title-input"
          />
        ) : (
          <h2 className="workspace-title">{workspace.title}</h2>
        )}
        <div className='workspace-action-btns'>
          {!isEditMode && (
            <>
              <button className="open-all-btn" onClick={openAllItems}>
                Open All
              </button>
            </>
          )}
          {isEditMode && (
            <button className="delete-workspace-btn" onClick={() => setIsDeleteModalOpen(true)}>
              Delete this Workspace
            </button>
          )}
          <button className={`edit-btn ${isEditMode ? 'pressed' : ''}`} onClick={toggleEditMode}>
            Edit
          </button>
          <button className="collapse-btn" onClick={toggleCollapse}>
            <IoIosArrowDown className={`collapse-icon ${isCollapsed ? 'rotated' : ''}`} />
          </button>
        </div>
      </div>
      {!isCollapsed && (
        <div className={`workspace-list ${hasManyItems ? 'has-many-items' : ''}`}>
          {workspace.items && workspace.items.length > 0 ? (
            <Swiper
              spaceBetween={0}
              slidesPerView={"auto"}
              scrollbar={{
                hide: 'true',
                draggable: 'true'
              }}
              modules={[Scrollbar]}
              className="mySwiper"
            >
              {workspace.items.map((item) => (
                <SwiperSlide key={item.id} onClick={() => openItem(item)}>
                  <WorkspaceItem
                    id={item.id}
                    title={item.title}
                    url={item.url}
                    homepageUrl={item.homepageUrl} // Pass homepage URL to WorkspaceItem
                    description={item.description}
                    icon={item.icon}
                    onDelete={() => onDeleteItem(item.id)}
                    isEditMode={isEditMode}
                    userId={userId}
                    workspaceId={workspace.id}
                    refetchWorkspaces={refetchWorkspaces}
                    settings={settings} // Pass settings to WorkspaceItem
                    commonApp={item.commonApp} // Pass commonApp to WorkspaceItem
                    openChoice={item.openChoice} // Pass openChoice to WorkspaceItem
                    isDemo={isDemo} // Pass the isDemo flag
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <p>No items in this workspace, click the button to add your first item</p>
          )}
          <AddButton onClick={openModal} />
        </div>
      )}
      <AddItemModal isOpen={isModalOpen} onClose={closeModal} onSubmit={handleAddItem} />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() => {
          onDelete(workspace.id);
          setIsDeleteModalOpen(false);
        }}
      />
      <hr className="workspace-separator" />
    </div>
  );
};
