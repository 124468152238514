// src/comps/Blog.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../css/Blog.css';

const blogs = [
  {
    title: "How to Optimize Your Productivity",
    date: "June 1, 2024",
    description: "Discover tips and tricks to maximize your productivity with ZenWorkspace.io.",
    keywords: "productivity, workflow management, ZenWorkspace.io, productivity tips"
  },
  {
    title: "Organizing Your Digital Life",
    date: "May 15, 2024",
    description: "Learn how to efficiently organize your digital life using custom workspaces.",
    keywords: "digital organization, custom workspaces, ZenWorkspace.io, organize digital life"
  },
  {
    title: "The Benefits of Custom Workspaces",
    date: "April 25, 2024",
    description: "Explore the various benefits of creating and using custom workspaces for different aspects of your digital life.",
    keywords: "custom workspaces, digital organization, ZenWorkspace.io, workspace benefits"
  }
];

export function Blog() {
  return (
    <div className="blog-container">
      <Helmet>
        <title>Our Blog | ZenWorkspace.io</title>
        <meta name="description" content="Insights and tips to help you get the most out of ZenWorkspace.io" />
        <meta name="keywords" content="blog, productivity, digital organization, custom workspaces" />
      </Helmet>
      <header className="blog-hero-section">
        <h1>Our Blog</h1>
        <p>Insights and tips to help you get the most out of ZenWorkspace.io</p>
      </header>

      <div className="blog-list">
        {blogs.map((blog, index) => (
          <Link to={`/blog/${blog.title.toLowerCase().replace(/ /g, '-')}`} key={index} className="blog-item-link">
            <div className="blog-item">
              <h2>{blog.title}</h2>
              <p className="blog-date">{blog.date}</p>
              <p>{blog.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
