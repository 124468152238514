import React from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';
import '../css/AddButton.css';

export const AddButton = ({ onClick }) => {
  return (
    <button className="add-btn" onClick={onClick}>
      <IoAddCircleOutline />
    </button>
  );
};
