import React, { useEffect, useState } from 'react';
import { auth } from '../config/firebase';
import firebase from '../config/firebase';
import Loading from './Loading';

const allowedEmails = ['cncv159753@gmail.com', 'ofri.yehuda27@gmail.com'];

export const Admin = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [collectionData, setCollectionData] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && allowedEmails.includes(user.email)) {
        setUser(user);
        fetchCollections();
      } else {
        auth.signOut();
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchCollections = async () => {
    try {
      console.log('Fetching collections from Firestore...');
      const response = await fetch('https://europe-west1-workspaces-56ac5.cloudfunctions.net/listCollections');
      const data = await response.json();
      console.log('Collections fetched from Firestore:', data);
      setCollections(data);
      if (data.length > 0) {
        setSelectedCollection(data[0]);
        fetchCollectionData(data[0]);
      }
    } catch (error) {
      console.error('Error fetching collections:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCollectionData = async (collectionId) => {
    try {
      console.log(`Fetching data for collection ${collectionId}...`);
      const response = await fetch(`https://europe-west1-workspaces-56ac5.cloudfunctions.net/getCollectionData?collectionId=${collectionId}`);
      const data = await response.json();
      console.log(`Data fetched for collection ${collectionId}:`, data);
      setCollectionData(data);
    } catch (error) {
      console.error(`Error fetching data for collection ${collectionId}:`, error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return <div>Please log in to view this page.</div>;
  }

  return (
    <div>
      <h1>Admin Panel</h1>
      <div className="tabs">
        {collections.map((id) => (
          <button
            key={id}
            className={id === selectedCollection ? 'tab active' : 'tab'}
            onClick={() => {
              setSelectedCollection(id);
              fetchCollectionData(id);
            }}
          >
            {id}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {selectedCollection === 'users' ? (
          <table>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {collectionData.map((doc) => (
                <tr key={doc.id}>
                  <td>
                    {doc.data.photoURL ? (
                      <img
                        src={doc.data.photoURL}
                        alt="Profile"
                        width="50"
                        height="50"
                      />
                    ) : (
                      'No Image'
                    )}&nbsp;{doc.data.fullname || 'Unknown'}</td>

                  <td>{doc.data.email || 'unknown@example.com'}</td>
                  
                </tr>
              ))}
            </tbody>
          </table>
        ) : selectedCollection ? (
          <table>
            <thead>
              <tr>
                <th>Data</th>
                <th>Subcollections</th>
              </tr>
            </thead>
            <tbody>
              {collectionData.map((doc) => (
                <tr key={doc.id}>
                  <td>
                    <pre>{JSON.stringify(doc.data, null, 2)}</pre>
                  </td>
                  <td>
                    {doc.subcollections && Object.keys(doc.subcollections).length > 0 ? (
                      Object.keys(doc.subcollections).map((subCol) => (
                        <div key={subCol}>
                          <h4>{subCol}</h4>
                          <pre>{JSON.stringify(doc.subcollections[subCol], null, 2)}</pre>
                        </div>
                      ))
                    ) : (
                      <span>No subcollections</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Select a collection to view its data.</p>
        )}
      </div>
    </div>
  );
};
