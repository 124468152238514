import React, { useState } from 'react';
import { BsBookmarkStar } from "react-icons/bs";
import Icon from './Icon';

export const BookmarkButton = ({ className }) => {
    const [showInstructions, setShowInstructions] = useState(false);

    const handleBookmark = () => {
        // Toggle the showInstructions state
        setShowInstructions(prevState => !prevState);
    };

    return (
        <div className={className}>
            <button onClick={handleBookmark}>
                <Icon icon={BsBookmarkStar} size="1.5em" color="black" className="settings-btn"/>
                Bookmark for Easy Access
            </button>
            {showInstructions && (
                <div>
                    <p>To bookmark your workspaces, press Ctrl+D (Cmd+D on Mac).</p>
                    <span style={{fontSize:'0.8em'}}>This will add your workspaces to the bookmarks bar in your browser.</span>
                </div>
            )}
        </div>
    );
    
};
