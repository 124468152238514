import React, { useState, useEffect } from 'react';
import '../css/DismissibleNotification.css';

export const DismissibleNotification = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const notificationDismissed = localStorage.getItem('notificationDismissed');
    if (notificationDismissed) {
      setIsVisible(false);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('notificationDismissed', 'true');
  };

  if (!isVisible) return null;

  return (
    <div className="notification">
      <span>For the best experiance, please allow popups</span>
      <button className="close-button" onClick={handleClose}>&times;</button>
    </div>
  );
};
