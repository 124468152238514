import React from 'react';
import '../css/PrivacyPolicy.css';

export function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>Effective Date: [Date]</p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to ZenWorkspace.io ("we," "our," or "us"). We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share your personal information when you visit our website <a href="https://zenworkspace.io">https://zenworkspace.io</a> (the "Site").
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        <strong>Personal Information:</strong> We may collect personal information such as your name, email address, and other contact details when you sign up for our services or contact us.<br/>
        <strong>Usage Data:</strong> We collect information about how you interact with our Site, including IP addresses, browser types, referring pages, and the pages you visit.
      </p>

      <h2>3. How We Use Your Information</h2>
      <p>We use your information to:</p>
      <ul>
        <li>Provide, maintain, and improve our services</li>
        <li>Communicate with you, including responding to your inquiries</li>
        <li>Personalize your experience on our Site</li>
        <li>Monitor and analyze usage and trends to improve our Site and services</li>
      </ul>

      <h2>4. Sharing Your Information</h2>
      <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except as described below:</p>
      <ul>
        <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our Site and conducting our business.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to valid requests by public authorities.</li>
      </ul>

      <h2>5. Security</h2>
      <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no internet transmission is completely secure, and we cannot guarantee absolute security.</p>

      <h2>6. Your Choices</h2>
      <p>You can opt-out of receiving marketing emails from us by following the unsubscribe link in those emails. You may also contact us to update or delete your personal information.</p>

      <h2>7. Third-Party Links</h2>
      <p>Our Site may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites.</p>

      <h2>8. Children's Privacy</h2>
      <p>Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.</p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our Site.</p>

      <h2>10. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at: Ofri.yehuda27@gmail.com</p>
    </div>
  );
}
