import React, { useState, useEffect } from 'react';
import '../css/Projects.css'; // Assuming you have a CSS file for Projects
import { Workspace } from './Workspace';
import { AddProjectModal } from './AddProjectModal'; // Import the add project modal component
import { DeleteProjectModal } from './DeleteProjectModal'; // Import the delete modal component
import { AddComponentModal } from './AddComponentModal'; // Import the add component modal
import { addProjectToFirestore, getProjects, deleteProjectFromFirestore, updateProjectComponents } from '../config/firefunctions'; // Import the delete and update functions
import { TodoList } from './Todolist'; // Import the TodoList component
import { Notes } from './Notes'; // Import the Notes component
import { KanbanBoard } from './KanbanBoard'; // Import the KanbanBoard component
import { UpgradePlanModal } from './UpgradePlanModal'; // Import the upgrade plan modal component
import { RiDeleteBinLine } from "react-icons/ri"; // Import the delete icon
import { CgPlayListAdd } from "react-icons/cg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';

export const Projects = ({ userId, workspaces, addProject, userPlan }) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [activeTab, setActiveTab] = useState(''); // No default tab
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isComponentModalOpen, setIsComponentModalOpen] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false); // State for upgrade modal
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false); // State for edit mode

  useEffect(() => {
    if (userId && userId !== 'demo_user') {
      const fetchProjects = async () => {
        const userProjects = await getProjects(userId);
        setProjects(userProjects);
      };
      fetchProjects();
    } else {
      // Initialize with empty projects for demo users
      setProjects([]);
    }
  }, [userId]);

  const openAddModal = () => {
    if (userPlan === 'Free' && projects.length >= 2) {
      setIsUpgradeModalOpen(true); // Open upgrade modal instead of alert
      return;
    }
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => setIsAddModalOpen(false);

  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const openComponentModal = () => setIsComponentModalOpen(true);
  const closeComponentModal = () => setIsComponentModalOpen(false);

  const handleProjectSelect = async (project) => {
    if (userId && userId !== 'demo_user') {
      const projectId = await addProjectToFirestore(userId, project);
      if (projectId) {
        project.id = projectId;
        setProjects([...projects, project]);
        setSelectedProject(project);
        closeAddModal();
      }
    } else {
      // Handle in-memory storage for demo users
      project.id = `demo_${Date.now()}`;
      setProjects([...projects, project]);
      setSelectedProject(project);
      closeAddModal();
    }
  };

  const handleComponentSubmit = async (components) => {
    if (selectedProject) {
      if (userId && userId !== 'demo_user') {
        await updateProjectComponents(userId, selectedProject.id, components);
      }
      setSelectedProject({ ...selectedProject, components });
      setProjects(projects.map(p => p.id === selectedProject.id ? { ...p, components } : p));
      if (components.length > 0) {
        setActiveTab(components[0]); // Set active tab to the first component in the list
      }
    }
    closeComponentModal();
  };

  const selectProject = (project) => {
    setSelectedProject(project);
    if (project.components && project.components.length > 0) {
      setActiveTab(project.components[0]); // Automatically set the first component as the active tab
    } else {
      setActiveTab(''); // Clear the active tab if there are no components
    }
  };

  const confirmDeleteProject = (project) => {
    setProjectToDelete(project);
    openDeleteModal();
  };

  const handleDeleteProject = async () => {
    if (userId && userId !== 'demo_user') {
      await deleteProjectFromFirestore(userId, projectToDelete.id);
    }
    setProjects(projects.filter(p => p.id !== projectToDelete.id));
    setProjectToDelete(null);
    closeDeleteModal();
    if (selectedProject?.id === projectToDelete.id) {
      setSelectedProject(null);
      setActiveTab('');
    }
  };

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  return (
    <div className="projects-container">
      <AddProjectModal 
        isOpen={isAddModalOpen} 
        onClose={closeAddModal} 
        workspaces={workspaces} 
        onSubmit={handleProjectSelect}
      />
      <div className='project-header'>
        <div className="projects-tabs">
          <Swiper
            spaceBetween={10}
            slidesPerView={"auto"}
            scrollbar={{ hide: 'true', draggable: 'true' }}
            modules={[Scrollbar]}
            className="mySwiper"
          >
            {projects.map((project, index) => (
              <SwiperSlide key={index} onClick={() => selectProject(project)}>
                <div className="project-item">
                  <button 
                    className={`project-name ${selectedProject?.id === project.id ? 'active' : ''}`} 
                    onClick={() => selectProject(project)}
                  >
                    {project.name}
                    {isEditMode && (
                    <button className="delete-project-btn" onClick={() => confirmDeleteProject(project)}>
                      <RiDeleteBinLine />
                    </button>
                  )}
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className='project-action-btns'>
          <button className="add-project-btn" onClick={openAddModal}>Add a New Project</button>
          <button className="edit-project-btn" onClick={toggleEditMode}>
            {isEditMode ? 'Done' : 'Edit'}
          </button>
        </div>
      </div>
      {projects.length === 0 ? (
          <div className="no-apps">
            <h2>No Projects Available</h2>
            <p>Add a new project to get started.</p>
        </div>
      ) : selectedProject && (
        <div className="selected-project">
          <Workspace 
            userId={userId} 
            workspace={selectedProject.workspace} 
            settings={{}} 
            onDelete={() => {}}
            onAddItem={() => {}}
            onDeleteItem={() => {}}
            refetchWorkspaces={() => {}}
          />
          <div className="project-tabs">
            {selectedProject.components?.length ? (
              <>
                {selectedProject.components.includes('todos') && (
                  <button 
                    className={`project-tab ${activeTab === 'todos' ? 'active' : ''}`} 
                    onClick={() => setActiveTab('todos')}
                  >
                    To-Do List
                  </button>
                )}
                {selectedProject.components.includes('kanban') && (
                  <button 
                    className={`project-tab ${activeTab === 'kanban' ? 'active' : ''}`} 
                    onClick={() => setActiveTab('kanban')}
                  >
                    Kanban Board
                  </button>
                )}
                {selectedProject.components.includes('notes') && (
                  <button 
                    className={`project-tab ${activeTab === 'notes' ? 'active' : ''}`} 
                    onClick={() => setActiveTab('notes')}
                  >
                    Notes
                  </button>
                )}
                <button className="add-component-btn" onClick={openComponentModal}>
                  <CgPlayListAdd />
                </button>
              </>
            ) : (
              <div className="no-components">
                <p>No Add-ons active, Click the button to add some</p>
                <button className="add-component-btn" onClick={openComponentModal}>
                  <CgPlayListAdd />
                </button>
              </div>
            )}
          </div>
          {activeTab === 'kanban' && selectedProject.components?.includes('kanban') && (
            <KanbanBoard userId={userId} projectId={selectedProject.id} />
          )}
          {activeTab === 'todos' && selectedProject.components?.includes('todos') && (
            <TodoList userId={userId} projectId={selectedProject.id} />
          )}
          {activeTab === 'notes' && selectedProject.components?.includes('notes') && (
            <Notes userId={userId} projectId={selectedProject.id} />
          )}
        </div>
      )}
      <AddComponentModal
        isOpen={isComponentModalOpen}
        onClose={closeComponentModal}
        onSubmit={handleComponentSubmit}
        selectedComponents={selectedProject?.components || []}
      />
      <DeleteProjectModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDeleteProject}
      />
      <UpgradePlanModal
        isOpen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
        currentPlan={userPlan}
      />
    </div>
  );
};
